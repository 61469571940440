.f-topVendorBtn {
  height: 20px;
  width: 14px;

  .f-icon--topSeller {
    font-size: 16px;
    color: @color-orange;
  }
}

.f-topVendorModal {
  &__title {
    color: #3164a0;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &__criteria {
    font-size: 14px;

    &__title {
      font-weight: 700;
    }
  }
}
