.f-faAvailability {
  line-height: 16px;
  color: @color-dark;

  &.f-productSection {
    margin: 0 auto;
  }

  &__option {
    display: inline-block;
    max-width: 90%;
    min-width: 90%;
  }

  &__title {
    margin-bottom: 10px;
    color: @color-dark;
    font-size: 13px;
    text-align: left;
    display: flex;
    align-items: center;
    height: 16px;
    font-weight: bold;

    &__text {
      font-weight: bold;
      font-family: @typoFnac;

      &--link {
        text-decoration: underline;
      }

      &.availabilityStatus,
      .f-buyBox__availabilityStatus--available {
        color: @color-lime-light;
      }
    }

    &__icon {
      width: 16px;
      height: 16px;
      margin: 0 3px 0 0;
      vertical-align: top;
      font-size: 16px;
    }

    &--iconFnac {
      margin: -2px 0 0 5px;
      width: 36px;
    }

    &__info {
      margin-left: 6px;
      font-size: 16px;
    }
  }

  &__shipping {
    &__price,
    .f-buyBox-deliveryPrice {
      font-weight: bold;
      color: @color-red;
    }

    &__info {
      margin: -2px 0 0 2px;
      padding: 0;
      vertical-align: top;
      color: @color-gray;
      font-size: inherit;
      background: none;
      border: 0;
      position: relative;
      outline: 0;
      order: 2;

      .f-icon {
        font-size: 16px;
      }

      @media only screen and (max-width: 360px) {
        display: none;
      }

      &__tooltip {
        background: @color-white;
        border-radius: 4px;
        box-shadow: 0 1px 4px rgba(@color-black, 0.5);
        color: @color-dark;
        font-size: 12px;
        min-width: 130px;
        max-width: 150px;
        opacity: 0;
        padding: 10px;
        position: absolute;
        text-align: left;
        top: 50%;
        transition: opacity 0.25s;
        transform: translate(-50%, 20%);
        visibility: hidden;
        z-index: 5;

        @media only screen and (max-width: 419px) {
          min-width: 100px;
          max-width: 120px;

          &::before {
            left: 42%;
          }
        }

        &::before {
          background: @color-white;
          border: 1px solid rgba(@color-black, 0.5);
          border-width: 1px 0 0 1px;
          content: '';
          height: 12px;
          position: absolute;
          top: 0;
          transform: translate(-42%, -50%) rotate(44deg);
          width: 12px;
          left: 44%;
        }
      }

      &:focus &__tooltip {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__status {
    font-weight: bold;

    &--available {
      color: @color-lime;
    }

    &--unavailable {
      color: @color-red2;
    }

    &--soon {
      color: @color-warning;
    }
  }

  &__drive {
    align-items: center;
    color: @color-dark;
    display: flex;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 25px;
  }

  &__driveIcon {
    height: 36px;
    margin-right: 8px;
    min-width: 40px;
    width: 40px;
  }

  .ff-button {
    margin-bottom: 10px;
    display: flex;

    &-icon {
      &::before {
        position: inherit;
        transform: inherit;
      }
    }

    .f-icon {
      margin-right: 5px;
      font-size: 16px;
    }

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__info {
    display: block;
  }
}
