.rating {
  @star-labo-size: 16px;

  &__star.f-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: @color-silver-darker;

    &:not(:first-child) {
      margin-left: 2px;
    }

    &.active {
      color: @color-blueLabo;
    }

    &.rating__star--labo {
      // width: @star-labo-size;
      // height: @star-labo-size;
      color: @color-blueLabo;
      border: 2px solid @color-blueLabo;
      border-radius: 50%;
      padding: 1px;
      font-size: @star-labo-size;
    }
  }

  &__note {
    display: flex;
    align-items: center;
    justify-content: center;

    &--total {
      color: @color-gray;
      margin-left: 5px;
    }
  }
}
