/* Présentation produit */
.mini-FA() {
  //position : relative;
  //.text-overflow();
  .title {
    font-size: 14px;
    a {
      color: @color-dark-lighter;
      text-decoration: none;
    }
  }
  .subtitle,
  .localisation {
    color: @color-gray;
  }
  .localisation,
  .date {
    color: @color-dark;
    font-size: 12px;
    font-weight: 700;
    .icon {
      margin-right: 5px;
    }
  }
  .container-prix {
    display: block;
    font-size: 14px;
    line-height: 1.3;
    margin-top: 5px;
  }
  .container-prixNb {
    color: @color-violetRed-hover;
    font-weight: 700;
  }
  .format {
    font-size: 12px;
    color: @color-gray;
    a {
      color: @color-skyBlue !important;
    }
  }
  .unavailable {
    font-size: 14px;
  }
  .price {
    font-size: 14px;
    sup {
      vertical-align: baseline;
      font-size: 14px;
    }
  }
  .oldPrice + .discountValue {
    font-size: 12px;
    font-weight: 700;
    color: #dd1e35;
  }
  .numerical {
    font-size: 14px;
    color: @color-skyBlue;
    &::before {
      color: @color-skyBlue;
      position: relative;
      display: inline-block;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
    }
    .price {
      font-size: 14px;
      color: @color-skyBlue;
    }
  }
  .mpSellerPrdPrice {
    top: -17px;
  }
}

/* Liste produit mosaic */
.displayMosaic(@width: 50%) {
  width: @width;
  float: left;
  margin: 0;
  padding: 0;
  min-height: 1px;
  .visuel {
    display: block;
    margin: 30px 10px 0 10px;
    text-align: center;
    height: 100px;
    img {
      height: 100px;
    }
  }
  .info-article {
    padding: 0.5em;
    & > * {
      .text-overflow();
    }
  }
  // .FA-details {
  //   position: absolute;
  //   bottom: 0;
  //   width: 100%;
  // }
  // .f-modelAvailable {
  //   font-size: 13px;
  // }
  &:nth-of-type(odd) {
    border-right: 1px solid @color-silver-darker;
  }
  .TEK {
    display: none;
  }
  .format {
    display: none !important;
  }
  .comment,
  .userOpinion {
    display: block !important;
    padding-top: 2px;
    .starsView {
      height: 15px;
      width: 94px;
      &::before,
      &::after {
        height: 15px;
        font-size: 20px;
      }
    }
  }
  .OPC .line {
    max-width: 100%;
  }
  .OPC .line + .line {
    display: none;
  }
  .line .discountValue {
    display: inline-block;
  }
  .oldPrice + .discountValue {
    display: none;
  }
}

/* Liste produit list */
.displayList() {
  .clearfix();
  .visuel,
  .info-article {
    float: left;
    width: 30%;
    margin: 0;
    padding: 0;
  }
  .visuel {
    display: block;
    text-align: center;
    img {
      padding: 0.5em;
    }
  }
  .info-article {
    width: 70%;
    position: relative;
    padding-bottom: 2em;
  }
  // .FA-details {
  //   width: 100%;
  // }

  // .price-comments {
  //   .count {
  //     display: none;
  //   }
  // }
  [id^='countdown_'] {
    text-align: center;
  }

  .visuel,
  .info-article {
    padding-top: 30px;
  }
  &.mini-FA--Spectacle {
    .info-article {
      padding: 2em 2em 2em 0;
      .icon_i_055_arrow_right_01 {
        position: absolute;
        right: 2%;
        top: 44%;
        &::before {
          font-size: 18px;
        }
      }
    }
  }
}
