.f-footer {
  color: @color-white;
  @link-font-weight: lighter;

  // Padding added for "Bons plans" sticky strata
  &.padded {
    padding-bottom: 55px;
  }

  // MobileAPP HACK
  .isMobileApp & {
    display: none;
  }

  &__downloadApp {
    img {
      display: block;
      width: 100%;
    }
  }

  &__links {
    background-color: @color-dark;

    &__list {
      border-bottom: 1px solid @color-gray-darker;
      padding: 3% 0 3% 4%;
      font-size: 0; // inline-block hack needed
    }

    &__title {
      font-size: 12px;
      text-transform: uppercase;
    }

    &__item {
      width: 50%;
      display: inline-block;
      font-size: 12px;
      padding: 3px 0;
    }
  }

  &__social {
    display: flex;
    width: 100%;
    background-color: @color-dark;
    border-bottom: 1px solid @color-gray-darker;

    html[lang*='-ES'] &,
    html[lang*='-PT'] & {
      background-color: #2793ad;
      border-bottom: 1px solid @color-gray-darker;
    }

    &-insertLogo {
      justify-content: center;
      padding: 24px 0;
    }

    &__item {
      align-items: center;
      display: flex;
      justify-content: center;
      flex: 1 0 auto;
      min-width: 55px;
      height: 60px;
      border-right: 1px solid @color-gray-darker;
      color: @color-white;
      padding: 0 12px;

      html[lang*='-ES'] &,
      html[lang*='-PT'] & {
        border-color: @color-white;
      }

      &:last-of-type {
        border-right-style: none;
      }
    }

    &__item-icon {
      font-size: 26px;
    }
  }

  &__xlinks {
    background-color: @color-black;
    font-size: 12px;
    padding: 25px 4%;

    // Shameless allocation of space for the fixed buy button when scrolling to the bottom of the article page.
    .FA & {
      padding-bottom: 75px;
    }
  }

  &__xlinks1 {
    display: flex;
    justify-content: space-between;
  }

  &__xlinks2 {
    margin: 15px 0 0;

    &__list {
      display: inline;
    }

    &__li {
      display: inline;
      list-style: none;

      &:nth-child(n + 2)::before {
        content: ' - ';
        margin: 0 2px;
      }
    }

    &__button {
      color: inherit;
      background-color: transparent;
      border: 0;
      padding: 0;
      font-size: inherit;
      font-family: inherit;
      font-weight: @link-font-weight;
      border-radius: 0;
    }
  }

  &__legal {
    font-size: 10px;
  }

  // FIXME use a class instead
  a {
    color: inherit;
    font-weight: @link-font-weight;
  }
}
