.f-headings__header {
  .mobile__strata-header();
}

.f-headings__h1 {
  .mobile__page-title();
}

.f-headings__h2 {
  .mobile__strata-title();
}

.f-headings__link {
  .mobile__strata-link();
}
