.f-breadcrumb {
  background: @color-silver-lighter;
  color: @color-gray;
  font-size: 12px;
  line-height: 1.5;
}

.f-breadcrumb__list {
  display: flex;
  list-style: none;
  overflow-x: auto;
  padding: 10px 16px;
  white-space: nowrap;
  width: 100%;
  -webkit-overflow-scrolling: touch;
}

.f-breadcrumb__item {
  display: inline-flex;

  &:not(:last-child)::after {
    content: '›';
    margin: 0 4px;
  }
}

.f-breadcrumb__link {
  color: inherit;
}
