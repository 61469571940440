@itemHeight: 70px;
@itemPadding: 25px;
@itemImageWidth: 30vw;

.f-catList {
  border-top: 1px solid @color-silver;

  &.open {
    .f-catItem:nth-child(n + 7) {
      max-height: @itemHeight;
      border-width: 1px;

      // lazy image fix (otherwise images will be loaded when element is hidden)
      img {
        display: initial;
      }
    }
  }

  .Strate-title {
    border-bottom: 1px solid @color-silver-dark;
  }
}

.f-catItem {
  width: 100%;
  border-bottom: 1px solid @color-silver-dark;
  overflow: hidden;
  max-height: @itemHeight;
  transition: all 0.3s ease;

  &:nth-child(n + 7) {
    max-height: 0;
    border-width: 0;

    // lazy image fix (otherwise images will be loaded when element is hidden)
    img {
      display: none;
    }
  }

  &--alone {
    position: relative;
    display: flex;
    align-items: center;
    height: @itemHeight;
  }
}

.f-catItem__main {
  overflow: hidden;
  transition: all 0.2s linear;
  position: relative;
  display: flex;
  align-items: center;
  height: @itemHeight;

  &:active {
    background-color: @color-silver-light;
  }

  &::after {
    content: '+';
    position: absolute;
    top: 50%;
    right: 23px;
    color: @color-black;
    font-size: 20px;
    transform: translateY(-50%);

    .f-catItem.open & {
      content: '−';
    }
  }
}

.f-catItem__imgWrapper {
  width: @itemImageWidth;
  min-width: @itemImageWidth;
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-catItem__title {
  font-size: 15px;
  line-height: 20px;
  padding-right: 35px;
  display: flex;
  align-items: center;
  transition: all 0.3s;

  .f-catItem.open & {
    font-weight: bold;
    transform: translateX(calc(-@itemImageWidth + @itemPadding));
  }
}

.f-catItem__img {
  transition: all 0.3s;
  backface-visibility: hidden;
  transform: translateZ(0);
  width: 47px;
  height: 47px;

  .f-catItem.open & {
    transform: translateY(@itemHeight);
  }
}

.f-catSubItem {
  display: block;
  position: relative;
  font-size: 14px;
  border-top: 1px solid @color-silver-dark;
  padding: 0 20px 0 @itemPadding;
  width: 100%;
  height: 42px;
  line-height: 42px;
  transition: all 0.2s linear;

  &:active {
    background-color: @color-silver-light;
  }

  &--all {
    border-left: 5px solid @color-orange;
    font-weight: bold;
    padding-left: 20px;
  }
}

.f-catSubItem__icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.f-catBtn {
  .fnacButton();
  .fnacButton--small();
  margin: 20px 1em 1em;
  width: calc(100% - 2em); // it's a button
}
