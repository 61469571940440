.f-trends {
  background-color: @color-silver-lighter;
  font-size: 14px;

  &__blocs {
    @margin: 5px;

    &-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding: 15px - @margin 15px - @margin;
    }

    &-li {
      flex: 1 1 40%;
      margin: @margin;
    }
  }

  &__bloc {
    .text-overflow();

    &-title {
      text-transform: uppercase;
      color: @color-dark;
    }

    &-links {
      &-list {
        list-style: none;
        padding: 0;
      }
    }

    &-link {
      color: lighten(@color-gray-darker, 30%);

      &:hover,
      &:active,
      &:focus {
        color: lighten(@color-gray-darker, 30%);
      }
    }
  }
}
