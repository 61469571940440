/* SMILE LIFESTYLE */

.lifestyle-wrapper--fiveBlocksVertical {
  .lifestyle-Item {
    &.nb1 {
      .imgLitleBlock();
      .contentLitleBlock();
      .blockFA();
    }
  }
}
.lifestyle-wrapper--fiveBlocksHorizontal {
  .lifestyle-Item {
    &.nb1 {
      .imgLitleBlock();
      .contentLitleBlock();
      .blockFA();
    }
  }
}

.lifestyle-wrapper.lifestyle .lifestyle-Item article {
  padding: 10px;
}

.lifestyle-wrapper--fourBlocksHorizontal {
  .lifestyle-Item {
    &.nb1,
    &.nb2 {
      .imgLitleBlock();
      .contentLitleBlock();
      .blockFA();
      .mini-FA {
        padding: 0 !important;
      }
    }
  }
}
.lifestyle-wrapper--fourBlocksVertical {
  .lifestyle-Item {
    &.nb2 {
      .imgLitleBlock();
      .contentLitleBlock();
      .blockFA();
    }
  }
}

.lifestyle {
  &-wrapper {
    &--black {
      color: @color-white;
      background-color: @color-dark-light;
    }

    &--blackless {
      color: @color-white;
      background-color: @color-dark-light;
    }

    &--middle {
      color: @color-white;
      background-color: @color-gray;
    }

    &--whiteless {
      color: @color-black;
      background-color: @color-silver-darker;
    }

    &--white {
      color: @color-black;
      background-color: @color-white;

      .lifestyle-Item {
        border: 1px solid #eee;
      }
    }
  }

  &-container {
    margin: 0 10px;
  }

  &-Item {
    margin: 0 0 10px;
    position: relative;

    &Content {
      background-color: @color-white;
      height: 100%;
      width: 100%;
      overflow: hidden;

      &.Video-content {
        .lifestyle-backgroundImage {
          padding-bottom: 100%;
        }
      }

      &--bottom {
        bottom: 0;
        height: auto;
        top: inherit;
      }

      &--Product {
        .mini-FA {
          border-right: 0;
          padding: 2em 1em !important;
        }
      }
    }

    &__Title {
      color: @color-black;
      font-size: 15px;
      padding: 3% 6% 0;
      text-align: left;
    }

    &__Text {
      color: @color-black;
      font-size: 13px;
      padding: 1% 6% 3%;
      text-align: left;
    }

    .info-article {
      text-align: left;

      .subtitle {
        text-align: left;
      }
    }

    .expertItem-content {
      padding: 5%;
    }

    .expertItem-vignette {
      width: 60px;
      height: 60px;
      overflow: hidden;
      position: absolute;
      top: 20px;
      left: 20px;
      border-radius: 50%;
      border: 2px solid @color-white;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .expertItem-header {
      padding: 40px 0 0 98px;
      margin-bottom: -10px;
      height: 80px;
      position: relative;
      overflow: hidden;
    }

    .expertItem-authorName {
      color: @color-orange;
      font-weight: bold;
    }

    .expertItem-category {
      font-size: 14px;
      color: @color-orange;
      margin-bottom: 5%;
    }

    .expertItem-title {
      color: @color-black;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 5%;
    }

    .expertItem-description {
      color: @color-black;
      font-size: 13px;
    }

    .expertItem-more {
      color: @color-orange;
      font-size: 13px;
    }

    .mini-FA {
      padding: 5%;
      text-align: left;
      color: @color-black;
    }

    .expertItem--ContributorImage .expertItem-header {
      padding: 28px 10px 0 98px;
    }
  }

  &-Article {
    &__head {
      display: block;
    }

    &__Image {
      margin: 0 auto;
      display: block;
    }

    .visuel {
      height: 200px !important;

      img {
        height: 200px !important;
      }
    }

    .mini-FA {
      width: 100% !important;
      border-right: 0 !important;

      .price-mini-FA {
        text-align: left;

        .price {
          font-size: 1.5em;
        }
      }
    }
  }
}

.lifestyle-Item--postControlImage {
  .expertItem-image {
    margin: 0 auto;
    display: block;
  }

  .expertItem-content {
    height: 50%;
    position: relative;

    a:not(.expertItem-title) {
      bottom: 0;
      position: absolute;
      width: 100%;
      background: @color-white;
      text-align: right;
      padding-right: 50px;
      padding-bottom: 13px;
    }
  }
}

.lifestyle-Item--contributor {
  .expertItem-category {
    margin-bottom: 0;
  }
}

.lifestyle-Item--Video {
  position: relative;
  text-align: center;

  a {
    text-align: center;
    display: block;
    height: 100%;
  }

  .lifestyle-backgroundImage {
    height: 100%;
    width: 100%;
    display: block;
    background-size: cover;
  }

  .lifestyle-pictoPlay {
    position: absolute;
    top: 38%;
    display: block;
    width: 100%;
  }

  .f-icon {
    font-size: 100px;
  }
}

.imgLitleBlock() {
  .visuel {
    float: left;
  }

  article .visuel img {
    height: 140px !important;
  }

  article .info-article {
    text-align: left;
  }

  article .price-mini-FA {
    text-align: left;
  }
}

.contentLitleBlock() {
  .lifestyle-ItemContent .expertItem-content {
    height: 100%;
    overflow: hidden;
    position: relative;

    a:not(.expertItem-title) {
      width: 100%;
      background: @color-white;
      display: block;
      position: absolute;
      bottom: 0px;
      height: 40px;
      text-align: right;
      padding-right: 40px;
      line-height: 40px;
    }
  }
}

.blockFA() {
  .mini-FA .info-article {
    padding: 2.5em 0.5em 0.5em;
  }
}
