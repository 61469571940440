@import (reference) '../../styles/_library.less';

.mosaic .f-searchSubOffer {
  display: none;
}

.f-searchSubOffer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding: 16px 8px 16px 16px;
  background-color: @color-silver-lighter;

  &--group {
    //fnacdarty
    background-color: #e5f0ed;
  }

  &__seller {
    font-size: 13px;
  }

  &__name {
    color: @color-marketplace;
    text-transform: uppercase;
    font-weight: bold;
  }

  &__icon {
    height: 16px;
    &--fulfilled {
      font-size: 32px;
      margin-top: -2px;
    }
  }

  .f-faMpSeller__statusLabel {
    display: block;
    margin-right: 8px;
    font-size: 13px;
    text-align: left;
    color: @color-dark;

    &::before {
      display: none;
    }
  }

  &__price {
    min-width: 75px;
    margin-right: 8px;
    margin-left: auto;
    font-size: 13px;
    line-height: 17px;
    text-align: right;

    .price {
      display: block;
      font-size: 15px;
      line-height: 19px;
    }
  }
}
