// christmasDelivery

.f-searchResults.mosaic .christmasDelivery {
  display: none;
}

.christmasDelivery {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: @color-noel;
  text-align: left;
  font-style: italic;
  margin-top: 10px;

  & &-newicon {
    min-width: 24px;
    min-height: 24px;
  }
  & &-label {
    display: inline-block;
    padding-left: 6px;
    margin: 0;
  }
  & &-link {
    display: inline-block;
    padding-left: 6px;
    color: @color-noel;

    &:hover,
    &:focus {
      color: @color-noel;
    }
  }
}

// Shipping Info
.shipping-info {
  .christmasDelivery {
    padding-left: 3px;
    margin-top: 10px;
  }

  .christmasDelivery-icon {
    position: absolute;
    top: -2px;
    left: 0;
  }

  .christmasDelivery-newicon {
    margin-right: 9px;
    margin-left: -3px;
  }
}

// FA Delivery
.FA-delivery {
  .christmasDelivery {
    padding: 16px;
    background-color: @color-silver-lighter;
    font-size: 13px;
    display: flex;

    .christmasDelivery-icon {
      position: absolute;
      top: 14px;
      left: 12px;
      margin-right: 5px;
    }

    .christmasDelivery-description {
      margin: 0 0 0 -24px;
      color: @color-gray;
    }

    .christmasDelivery-newicon {
      flex-basis: 8%;
      margin-right: 9px !important;
      margin-left: -3px !important;
    }
  }
}
