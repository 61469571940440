//
// 1. Force the browser to use author font size not a computed one based on screen size
//
html {
  font-size: 62.5%;
  text-size-adjust: 100%; // 1
}

body {
  font: normal normal @font-size-base / 1.5 @typoFnac;
  margin: 0;
  padding: 0;
  background: #fff;

  &.noScroll {
    overflow: hidden;
  }
}

a {
  color: #3c3c3b;
  text-decoration: none;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  margin: 0;
}

nav ul {
  list-style: none;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

table {
  border-collapse: collapse;
}

button {
  background: none;
  border: 0;
  color: inherit;
}
