@import (reference) '../../../../../../styles/_library-mobile.less';

.shelves {
  background: @color-silver-lighter;
}

.shelves__header {
  .mobile__strata-title();
}

.shelves__content {
  display: flex;
  overflow-x: auto;
  padding: 0 1em 1em;
  scroll-behavior: smooth;
  scroll-padding: 0 1em;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;

  &::after {
    content: '';
    min-width: 1px;
    margin: 0 0 0 -1px;
  }
}

.shelves__card {
  @cardHeight: 112px;
  @cardWidth: 120px;

  align-items: center;
  background: @color-white;
  border-radius: @base-radius;
  box-shadow: 0 0 14px 0 rgba(@color-black, 0.05), 0 6px 9px 0 rgba(@color-black, 0.05),
    0 2px 5px 0 rgba(@color-black, 0.05);
  display: flex;
  flex: 0 0 @cardWidth;
  flex-direction: column;
  height: @cardHeight;
  justify-content: space-between;
  margin: 0 1em 0 0;
  overflow: hidden;
  padding: 6px;
  position: relative;
  scroll-snap-align: start;

  &::after {
    content: '';
  }
}

.shelves__card_image {
  height: 65px;

  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}

.shelves__card_link {
  @fontSize: 13px;
  @lineHeight: 1.3;
  @lines: 2;

  color: @color-dark;
  display: block; // fallback
  display: -webkit-box;
  font-size: @fontSize;
  line-height: @lineHeight;
  max-height: ceil((@fontSize * @lineHeight) * @lines); // fallback
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: @lines;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
