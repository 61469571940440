@radioSize: 22px;

.f-radio {
  position: relative;
  display: block;
  width: @radioSize;
  height: @radioSize;
  cursor: pointer;

  @supports (appearance: none) {
    appearance: none;
    border: 1px solid @color-silver-darker;
    background: @color-white;
    border-radius: 50%;
    outline: none; // TODO use box-shadow to draw focus ring
    @scale: 0.56;

    &::after {
      content: '';
      opacity: 0;
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(@scale * 1.25);
      transition: transform 0.3s ease, opacity 0.2s;
      background-color: @color-orange;
    }

    &:checked::after {
      opacity: 1;
      transform: scale(@scale);
    }
  }
}
