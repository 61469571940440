.f-burger {
  width: 48px;
  height: 48px;
  background: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  position: fixed;
  left: 12px;

  &__content {
    position: relative;
    width: 16px;
    height: 15px;
  }

  &__bar {
    position: absolute;
    left: 0;
    right: 0;
    background: @color-dark;
    height: 2px;
    transition: 0.25s ease-in-out;

    &--1 {
      top: 0;
    }

    &--2,
    &--3 {
      top: 6px;
    }

    &--4 {
      top: 12px;
    }

    .menu-open &--1,
    .menu-open &--4 {
      opacity: 0;
      width: 0;
      top: 6px;
    }

    .menu-open &--2 {
      transform: translateZ(0) rotate(45deg);
    }

    .menu-open &--3 {
      transform: translateZ(0) rotate(-45deg);
    }
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-burger__bar {
  background: @color-white;
}
