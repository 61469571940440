.f-carousel {
  padding-bottom: 8px;

  &__scroller {
    .scrollsnap();
  }

  &__item {
    scroll-snap-align: center;
    min-width: 100vw;

    &--image {
      display: flex;
      height: 240px;
      justify-content: center;
      align-items: center;
    }
  }

  &__img {
    max-width: 240px;
    max-height: 240px;
    width: auto;
    height: auto;
  }

  &__indicators {
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 24px;
    padding: 0 8px;
    text-align: center;
  }

  &__indicator {
    display: inline-block;
    background: @color-silver-darker;
    width: 8px;
    height: 8px;
    margin: 4px;
    border-radius: 50%;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:only-child {
      display: none;
    }

    &--active {
      background: @color-orange;
    }

    &--audio {
      --svg: data-uri(icon('icon_i_319_audio'));
      mask: var(--svg);
      width: 12px;
      height: 12px;
    }
  }
}
