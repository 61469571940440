@import (reference) '../../styles/_library.less';

// Menu & nav Mobile
.f-menu {
  position: fixed;
  transform: translateX(-100%);
  left: 0;
  bottom: 0;
  top: 56px;
  z-index: 5000;
  width: 100%;
  background-color: @color-white;
  color: @color-dark;
  overflow-y: scroll;
  will-change: transform;
  transition: transform 0.25s ease-in-out;
  -webkit-overflow-scrolling: touch; // hack ios safari : scrolling + position fixed
  border: 1px solid @color-silver-darker;
  border-width: 1px 0 0;
  // overscroll-behavior: contain;

  .menu-open & {
    transform: translateX(0);

    .searchbar-open& {
      display: none;
    }
  }

  &__title {
    // display: flex;
    // align-items: center;
    // height: 35px;
    background: @color-dark;
    // font-size: 13px;
    // font-weight: bold;
    padding: 8px 16px;
    // text-transform: uppercase;
    color: @color-white;
  }

  &__list {
    position: relative;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 16px;
    width: 100%;
    height: 44px;
    color: @color-dark;
    border: 1px solid #e7e7e7;
    border-width: 0 0 1px;
    transition: background-color 0.1s;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: @color-white;

    &-icon {
      font-size: 16px;
      margin-right: 16px;
    }

    &-arrow {
      // position: absolute;
      // right: 10px;
      // color: @color-silver-darker;
      font-size: 16px;
      line-height: 1;
      margin-left: auto;
    }

    // menu FR only
    html[lang='fr-FR'] & {
      &:nth-child(1n + 21) {
        background-color: @color-silver-lighter;
      }
    }
    // menu CH only
    html[lang='fr-CH'] & {
      &:nth-child(1n + 18) {
        background-color: @color-silver-lighter;
      }
    }
    // menu BE only
    html[lang='fr-BE'] & {
      &:nth-child(1n + 20) {
        background-color: @color-silver-lighter;
      }
    }
    html[lang='nl-BE'] & {
      &:nth-child(1n + 20) {
        background-color: @color-silver-lighter;
      }
    }

    // menu PT only
    html[lang='pt-PT'] & {
      &:nth-child(1n + 21) {
        background-color: @color-silver-lighter;
      }
    }

    // menu ES only
    html[lang='es-ES'] & {
      &:nth-child(1n + 17) {
        background-color: @color-silver-lighter;
      }
    }

    &:active {
      background: @color-silver-dark;
    }

    &--secondary {
      padding-left: 32px;
    }

    &--back {
      background: @color-silver-lighter;
      border-top: 1px solid #e7e7e7;
    }

    &--current {
      border-left: 5px solid @color-orange;
      font-weight: bold;
      padding-left: 27px;
    }
  }

  &__secondary {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: @color-white;
    will-change: transform;
    transition: transform 0.25s ease-in-out;
    transform: translateX(100%);
    z-index: 10;

    &.isActive {
      transform: translateX(0);
    }
  }
}
