.mp-label {
  color: @color-marketplace;
}

.count {
  color: @color-gray !important;
}

.price {
  color: @color-red;
  font-weight: bold;
}

.price--mp {
  color: @color-marketplace;
  font-weight: bold;
}

.price--striked {
  color: @color-dark;
  text-decoration: line-through;
}

.fnacButton {
  .fnacButton();

  &__icon {
    padding-right: 5px;
    font-size: 24px;
  }

  &--ebook {
    .fnacButton--ebook();

    // .FA-buybox & {
    //   width: calc(100% - 2em);
    //   position: initial;

    //   .f-icon {
    //     font-size: 18px;
    //   }
    // }
  }

  &--block {
    .fnacButton--block();
  }

  &--inline {
    .fnacButton--inline();
  }

  &--fixed {
    .fnacButton--fixed();
  }

  &--small {
    .fnacButton--small();
  }

  &--very-small {
    .fnacButton--very-small();
  }
  // colors
  &--gold {
    .fnacButton--gold();
  }

  &--black {
    .fnacButton--black();
  }

  &--emerald {
    .fnacButton--emerald();
  }

  &--blue {
    .fnacButton--blue();
  }

  &--green {
    .fnacButton--green();
  }

  &--emerald {
    .fnacButton--emerald();
  }
}

.isMandatory {
  .mandatory('*');
}
