@import (reference) '../../../../../../styles/_library-mobile.less';

.trends {
  @trendsCardSize: 216px;
  @trendsCardHeight: 137px;

  background: @color-white;

  &__header {
    .mobile__strata-title();
  }

  &__content {
    display: flex;
    list-style: none;
    overflow-x: auto;
    margin-top: -1em;
    padding: 1em;
    scroll-behavior: smooth;
    scroll-padding: 0 1em;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    &::after {
      content: '';
      min-width: 1px;
      margin: 0 0 0 -1px;
    }
  }

  &__card {
    aspect-ratio: 1;
    background-size: cover;
    border-radius: @base-radius;
    box-shadow: 0 0 12px 0 rgba(@color-black, 0.05), 0 5px 8px 0 rgba(@color-black, 0.05),
      0 2px 4px 0 rgba(@color-black, 0.05);
    display: flex;
    flex: 0 0 @trendsCardSize;
    height: @trendsCardHeight; // fallback of aspect-ratio // new ratio after ABtest
    margin: 0 1em 0 0;
    overflow: hidden;
    position: relative;
    scroll-snap-align: start;
  }

  &__card_link {
    align-items: flex-end;
    background: linear-gradient(
      to bottom,
      #0000,
      rgba(27, 27, 27, 0.42) 48%,
      rgba(@color-black, 0.47) 56%,
      @color-dark 100%
    );
    display: flex;
    padding: 0 8px 13px;
    width: @trendsCardSize;
  }

  &__card_text {
    @fontSize: 15px;
    @lineHeight: unit((16px / @fontSize));
    @lines: 2;

    color: @color-white;
    display: block; // fallback
    display: -webkit-box;
    font-size: @fontSize;
    line-height: @lineHeight;
    max-height: ceil((@fontSize * @lineHeight) * @lines); // fallback
    overflow: hidden;
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    z-index: 2;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: @lines;
  }
}
