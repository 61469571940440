// #region Search Result
.mobileProductList {
  .nbResults {
    font-size: 13px;
    padding: 0 0 0.5em 1em;
    border-bottom: 1px solid @color-silver-darker;
  }

  .clearfix();

  article {
    border-bottom: 1px solid @color-silver-darker;
    .mini-FA();

    .mosaic {
      .displayMosaic();

      .title,
      .price-mini-FA {
        font-size: 14px !important;
      }

      .button-Basket {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        height: 40px;
        width: 40px;
        line-height: 40px;
        margin: 0.5em;
        text-align: center;
        padding: 0px;

        &::before {
          color: @color-white;
          font-size: 25px;
          padding-right: 0;
        }
      }

      .userPrice sup {
        vertical-align: baseline;
        font-size: 1em;
      }

      .subtitle {
        font-size: 12px;
        height: 18px;
      }
    }

    .list {
      .displayList();

      .info-article {
        padding-bottom: 2em;
        position: static;
      }

      .button-Basket {
        display: inline-block;
        padding: 0 10px;
        position: absolute;
        right: 10px;
        bottom: 10px;

        &::before {
          padding: 0 5px;
        }
      }

      .title {
        font-size: 16px;
      }

      .subtitle,
      .format,
      .localisation {
        font-size: 13px;
      }

      .HL {
        left: 0;
        right: auto;
        font-size: 12px;
      }
    }

    .actionItem {
      font-size: 12px;

      a {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.05em;
        display: block;
      }

      .sellBy {
        color: #989898;
      }

      .sellBy-seller {
        color: #004179;
      }

      .seller {
        font-weight: bold;
        color: #095287;
      }

      .emptyMPLink {
        visibility: hidden;
        font-size: 10px;
      }
    }

    .numericalFormat {
      font-size: 12px;
      color: @color-silver-darker;
      max-width: 75%;

      i {
        color: @color-skyBlue;

        &::before {
          font-size: 14px;
        }
      }

      .price {
        color: @color-skyBlue;
        font-size: 12px;
      }
    }

    .shop-availability {
      color: #6a9d47;
      border-radius: 20px;
      border: 1px solid #6a9d47;
      display: inline-block;
      font-size: 11px;
      padding: 2px 10px;
      margin: 4px 0;
    }

    .price-mini-FA {
      text-align: left;
      float: none;

      &.numerical {
        &::before {
          color: @color-skyBlue;
          position: relative;
          display: inline-block;
          line-height: 1;
          font-weight: normal;
          font-style: normal;
          text-transform: none;
          -webkit-font-smoothing: antialiased;
        }

        .price {
          color: @color-skyBlue;
        }

        div {
          display: inline-block;
        }
      }
    }

    .comment {
      font-size: 14px;
    }
  }

  .mini-FA--sponsor {
    position: relative;

    .mini-FA {
      width: 100%;

      &:nth-of-type(odd) {
        border: 0;
      }
    }

    .thumbnail-sponsor {
      position: absolute;
      bottom: 2px;
      left: 4px;
      font-size: 0.6em;
      color: @color-gray-dark;
    }
  }

  .mini-FA--sponsor {
    position: relative;

    .mini-FA {
      width: 100%;

      &:nth-of-type(odd) {
        border: 0;
      }
    }

    .thumbnail-sponsor {
      position: absolute;
      bottom: 2px;
      left: 4px;
      font-size: 0.6em;
      color: @color-gray-dark;
    }
  }
}
