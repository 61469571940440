/* OPC MP */
.popincontainerMP {
  margin: 0 20px;
  background: @color-white;
  padding: 20px;

  &-title {
    border-bottom: 1px solid @color-gray;
    text-transform: capitalize;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 16px;
  }

  &-text {
    font-size: 12px;
  }

  .mfp-close {
    color: @color-dark !important;
    right: 20px !important;
  }
}
