.f-anim-fade {
  opacity: 0;
  transition: opacity .2s ease-in-out;
  pointer-events: none;

  &--done {
    pointer-events: initial;
    opacity: 1;
  }
}

.f-anim-slideBottom {
  bottom: -100%;
  transition: .6s ease-in-out;

  &--done {
    will-change: bottom;
    bottom: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animGreyBg {
  from {
    background-color: @color-silver-light;
  }

  to {
    background-color: #FFF;
  }
}

@keyframes animGreyBg2 {
  from {
    background-color: @color-dark;
  }

  to {
    background-color: @color-silver-light;
  }
}

@keyframes animGreyBleuBg {
  from {
    background-color: @color-white;
  }

  to {
    background-color: @color-silver-light;
  }
}
