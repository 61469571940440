.lastSeenArticles {
  header {
    a {
      .icon {
        margin-left: 5px;

        &::before {
          font-size: 12px;
        }
      }
    }
  }

  .simpleCarousel-element {
    max-width: 50%; // allow to grow but only 50%, useful for <= 2
  }

  .blocPriceAdhOrNot .adh-price {
    margin-right: 3.5%;
    padding-right: 3.5%;
  }
}
