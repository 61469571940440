@import '../../../../../styles/_library-mobile.less';

.FA-experts {
  h2,
  h2.open {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;

    & + .open {
      background: #e6e6e6;
    }
  }

  .carousselExpert {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    // margin: 0.5em;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar-track {
      background-color: @color-grey-dark;
    }

    &::-webkit-scrollbar {
      width: 7px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      min-width: 12px;
      min-height: 5px;
      background-color: @color-gray-dark;
      border: none;
    }

    .container {
      display: inline-block;
    }
  }

  .expertItem {
    //width: 90%;
    white-space: initial;
    display: inline-block;
    vertical-align: top;
    padding: 0.5em;
    background: @color-white;
    position: relative;
    scroll-snap-align: center;

    &-header {
      .clearfix();
      padding-bottom: 1em;
      margin-bottom: 1em;
      border-bottom: 1px solid @color-gray-darker;
    }

    &-vignette {
      width: 50px;
      height: 50px;
      display: block;
      background-size: cover;
      border-radius: 50%;
      margin-right: 10px;
    }

    &-author {
      width: 70%;
    }

    &-vignette,
    &-author {
      display: inline-block;
      vertical-align: middle;
    }

    &-authorName {
      font-size: 15px;
      font-weight: 700;
    }

    &-authorCategory {
      font-size: 13px;
      line-height: 1.2;
      overflow: hidden;
    }

    &-categorie {
      color: @color-orange;
      font-size: 13px;
    }

    &-title {
      font-size: 15px;
      display: block;
      font-weight: 700;
      max-height: 40px;
      height: 40px;
      overflow: hidden;
      margin-bottom: 10px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }

    &-list {
      display: none;
      list-style: none;
    }

    &-description {
      font-size: 13px;
      max-height: 110px;
      height: 110px;
      overflow: hidden;
      margin-bottom: 10px;
    }

    &-more {
      margin: 0.5em 0;
    }
  }
}
