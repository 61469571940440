.f-servicesInserts {
  &__collapseWarranty {
    transition: height 0.35s ease-in-out;
    overflow: hidden;
  }
  &__collapseWarranty:not(.active) {
    display: none;
  }

  &__collapseButton {
    display: flex;
    margin-top: 10px;
    margin-left: auto;
    text-decoration: underline;
  }

  &__element {
    display: flex;
    background-color: @color-grey;
    padding: 12px;
    margin: 0 0 16px;
    border-radius: @base-radius;
    align-items: center;
  }

  &__content {
    position: relative;
    display: block;
    margin-left: 11px;
    line-height: 1.2;
    min-height: 22px;
    flex-grow: 1;
    font-size: 1.3rem;
  }

  &__title {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }

  &__text {
    display: inline-block;
    color: @color-gray-darker;
    font-weight: normal;
  }

  &__info {
    cursor: pointer;
    font-size: 1.6rem;
    color: @color-dark;
    text-decoration: underline;
  }

  &__icon {
    font-size: 1.6rem;
  }
}
