.f-market {
  &__title {
    .mobile__strata-title();
    border-bottom: 1px solid @color-silver-dark;
  }

  // &__chevron,
  .f-market__item__title {
    text-transform: uppercase;
    // font-weight: 700;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px solid @color-silver-dark;
    height: 42px;
    line-height: 24px;
  }

  .f-market__item__title {
    padding: 10px 35px 10px 10px;
    border-top: none;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    padding: 10px 20px 10px 10px;
    border-bottom: 1px solid @color-silver-dark;

    &--bold {
      font-weight: bold;
    }

    &::after {
      content: '';
      width: 7px;
      height: 7px;
      border: solid @color-dark;
      border-width: 0 1px 1px 0;
      transform: rotate(-45deg);
    }
  }
}
