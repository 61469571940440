@import (reference) '../../../../../styles/_library-mobile.less';

/*#region Rate/stars */

/* Html struct:
	<span class="starsView long45">
		<span>4,5</span>
	</span>

*/

/*
	[Float] @rate : between 0 and 5
	[String] @templateChar : value of the template from Fnac font "icons" (see variables.less)
	[String] @separatorChar
*/

//
// Styleguide 2.1
//
// Classic star style with only
//

.starsView {
  @star-bg: colorize(data-uri(icon('icon_i_097_Star')), @color-silver-darker);
  @star-fg: colorize(data-uri(icon('icon_i_097_Star')), @color-orange);

  overflow: hidden;
  font-size: 16px;
  width: 5em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: @color-silver-darker;
  white-space: nowrap;
  text-indent: 100%;
  line-height: 1;

  &::before,
  &::after {
    content: '';
    background-image: @star-bg;
    background-repeat: repeat-x;
    background-size: 1em;
    height: 1em;
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    background-image: @star-fg;
    width: 0;
    overflow: hidden;
  }

  &.long0::after {
    width: 0;
  }

  &.long0\.5::after {
    width: 10%;
  }

  &.long1::after {
    width: 20%;
  }

  &.long1\.5::after {
    width: 30%;
  }

  &.long2::after {
    width: 40%;
  }

  &.long2\.5::after {
    width: 50%;
  }

  &.long3::after {
    width: 60%;
  }

  &.long3\.5::after {
    width: 70%;
  }

  &.long4::after {
    width: 80%;
  }

  &.long4\.5::after {
    width: 90%;
  }

  &.long5::after {
    width: 100%;
  }

  &-count {
    font-size: 12px;
    padding-left: 5px;
    color: @color-gray;
  }
}
