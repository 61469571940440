/* stylelint-disable max-nesting-depth, selector-max-combinators, selector-max-compound-selectors -- Selectors are too complicated here, refactor is needed */

@import (reference) '../../../../styles/_library-mobile.less';

// Layout global
@import './layout/layout.less';
@import '../../../../Nav/Css/Less/common/layout/partners-img.less';
@import '../../Less/common/base/type.less';

// a11y styles
@import '../../Less/common/utils/_a11y.less';

// old mixins common now
@import 'common/Carousel.less';
@import '../../Less/common/components/productCarousel.less';
@import 'common/rates.less';
@import 'common/rating.less';
@import 'common/FA.less';
@import 'common/_utilities.less';
@import 'common/_headings.less';
@import 'common/grid.less';
@import 'common/thumbnail.less';
@import 'common/popin.less';
@import 'common/BasketPopin.less';
@import '../../../../styles/common/components/stimuliOPC.less';
@import 'common/reset.less';
@import 'common/productList.less';
@import 'common/sponsor.less';
@import 'common/components/christmasDelivery.less';
@import 'common/category-list.less';
@import '../../../../styles/common/elements/text-logo.less';
@import '../../../../styles/common/elements/stars.less';

@import 'FA/community.less';
@import 'FA/buybox.less';
@import 'FA/availablility.less';
@import 'FA/select.less';

// Layers
// TODO: import Smile layers styles ONLY on pages with Smile content, not in common !
@import 'layout/strates/banner.less';
@import 'layout/strates/homeProduits.less';
@import 'layout/strates/mosaic.less';
@import 'layout/strates/productList.less';
@import 'layout/strates/history.less';
@import 'layout/strates/homeSuggestions.less';
@import 'layout/strates/seo.less';
@import 'layout/strates/experts.less';
@import 'layout/strates/reassurance.less';
@import 'layout/strates/lifestyle.less';
@import 'layout/strates/seoLinks.less';
@import 'layout/strates/stickyGoodDeal.less';
@import 'layout/strates/stackedCards.less';
@import 'layout/strates/trends.less';
@import 'layout/strates/shelves.less';
@import 'layout/strates/recommendation.less';

@import 'FA/experts.less';

//@import 'common/magnific-popup.less';
@import '../../../../styles/common/components/modals.less';

// used in lifestyle pages as well...
@import 'search/result.less';
@import 'search/resultList.less';

// Components
// Reusable components with only one function
@import 'common/_trends.less';
@import 'common/topVendor.less';
//@import 'common/autocomplete2.less';
@import 'common/carouselScrollSnap.less';
@import '../../Less/common/components/checkbox.less';
@import '../../../../styles/common/components/radio.less';
//@import 'common/modal.less';
@import 'common/market.less';
// @import 'common/components/popover.less';
@import 'common/animations.less';
@import '../../Less/common/components/miniFA.less';
@import '../../Less/common/components/countdown.less';
@import '../../Less/common/components/progressBar.less';
@import '../../Less/common/components/infoTooltip.less';

@import '../../Less/common/components/chart/gaugeChart.less';
// @import '../../Less/common/components/compare/banner.less';
@import '../../Less/common/popins/_wishlist-modal.less';
@import '../../Less/common/tooltip/popoverTopVendor.less';

// Advertising Appnexus
@import '../../../../styles/common/_advertising.less';

// consent placeholder
//@import '../../../../styles/common/components/mediaPlaceholder.less';

// Loading
@import '../../Less/views/loading/miniFA.less';

/*****  COF REFONTE FA lot 0 *******/
// @import '../../Less/views/product/ProductOffers/moreOffers.less';

// Shared with desktop
@import '../../Less/views/product/ProductBuyBox/servicesInserts.less';
@import '../../Less/views/product/ProductOffers/offerList.mobile.less';
@import '../../Less/views/product/ProductOffers/seller.less';
// @import '../../../../styles/common/components/forms.less';

// Don't add any styles below this line
// --------------------------------------------------------
// Code below must be cleaned up

// important
@main-padding-left: 0;
@main-padding-top: 123px;
@main-padding-top-sm: 115px;

// .js-seeMoreText-hidePart {
//   display: none;

//   &.active {
//     display: initial;
//   }
// }

#waitingMsg {
  display: none;
}

.hide,
.empty,
.hidden,
[hidden] {
  display: none !important;
}

.errorMessage {
  & > span {
    display: block;
  }
}

#content {
  .HL {
    font-size: 0.8em;
    display: inline-block;
    padding: 2px 6px;
    text-transform: uppercase;
  }

  .hlRoug {
    background-color: #c00;
    color: #fff;
  }

  .hlJaun {
    background-color: @color-yellow;
    color: @color-black;
  }
}

.rouge,
.redPrice {
  color: #c00 !important;
}

.vert {
  color: #5c9903 !important;
}

// HOME PRODUITS
// TODO: move into home-products.less
.home-PRD,
.SousHome {
  #staticMosaic {
    list-style: none;
    border-top: 1px solid @color-silver-light;
    border-left: 1px solid @color-silver-light;
    margin-bottom: 10px;
    .clearfix();

    li {
      text-align: center;
      border-right: 1px solid @color-silver-light;
      border-bottom: 1px solid @color-silver-light;
      float: left;
      width: 33.3%;
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: center 30%;
      position: relative;
      padding-bottom: 30%;

      a {
        font-size: 12px;
        position: absolute;
        width: 100%;
        height: 100%;
        line-height: 1;
        display: block;
        text-align: center;

        &:visited {
          border: 1px solid @color-dark;
        }

        span {
          display: inline-block;
          width: 100%;
          bottom: 5px;
          position: absolute;
          left: 0;
        }
      }
    }

    & + header.title {
      border-top: 0;
      padding: 0 10px 10px;
    }
  }

  .noeuds {
    border-top: 1px solid @color-silver-light;

    dd {
      border-bottom: 1px solid @color-silver-light;
    }

    & > a {
      display: block;
      padding: 10px 10px 10px 0;
      margin-left: 20px;
      border-bottom: 1px solid @color-silver-light;

      &:last-of-type {
        border-bottom-style: none;
      }
    }

    &#swipe {
      white-space: nowrap;
      overflow: scroll;
      max-height: 85px;

      &::-webkit-scrollbar {
        width: 0em;
      }

      dd {
        border-bottom: 1px solid @color-silver-light;
        border-right: 1px solid @color-silver-light;
        display: inline-block;
        width: 30%;
        height: 85px;
        margin-right: -4px;

        p {
          font-size: 11px;
        }

        &.full {
          width: 33.3%;
        }
      }

      .picto {
        background-image: url('../../../Images/mobile/sprite_best_offers.png');
        background-repeat: no-repeat;
        height: 70px;
        width: 100%;
        display: block;
        text-indent: -999em;
        background-position-x: center;

        @media (min-resolution: 2dppx) {
          background-image: url('../../../Images/mobile/sprite_best_offers_HD.png');
          background-size: 80px;
        }
      }

      span[class*='meilleures-offres-venteflash'] {
        background-position-y: 8px;
      }

      span[class*='offreadhérents'],
      span[class*='meilleures-offres-adh'] {
        background-position-y: -55px;
      }

      span[class*=' 168x28_picto_packs_securite'] {
        background-position-y: -119px;
      }

      span[class*='packs-micro'] {
        background-position-y: -182px;
      }

      span[class*=' rentree-picto'] {
        background-position-y: -246px;
      }

      span[class*='offres-remboursement'] {
        background-position-y: -309px;
      }

      span[class*=' meilleures-offres-new'] {
        // pack fnac
        background-position-y: -390px;
      }

      span[class*=' bons-plans'] {
        background-position-y: -451px;
      }

      span[class*=' meilleures-offres-new-soldes'],
      span[class*=' soldes-'] {
        background-position-y: -572px;
      }

      span[class*='offres-occasion'] {
        background-position-y: -635px;
      }

      span[class*='destockage'] {
        background-position-y: -760px;
      }

      span[class*='noel'] {
        background-position-y: -821px;
      }
    }
  }
}

.home-PRD {
  .noeuds {
    .content-shelfItem {
      font-weight: bold;
    }
  }

  & > .noeuds {
    border-top: 0;
  }

  a {
    font-weight: lighter;
    color: @color-dark-lighter;

    &.chevron {
      text-transform: none;
    }

    &.light {
      font-weight: normal !important;
    }

    &.viewAll {
      width: 25%;
      padding: 5px 0;
      border: 1px solid #3c3c3b;
      border-radius: @base-radius;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
  }
}

// #region Search Result
// TODO make uniform product list accross search, product and lifestyle
// search list is in search/resultList.less for performance reasons
.mobileProductList,
.lifestyle {
  .clearfix();

  article {
    position: relative;
    .mini-FA();

    .shipping-info {
      display: none !important;
    }

    .searchFA {
      .clearfix();
    }

    .lifestyle-wrapper& {
      .displayMosaic();

      article {
        width: 100% !important;
        border-right: 0 !important;
      }
    }

    .mosaic& {
      .displayMosaic();

      .title,
      .price-mini-FA,
      .author {
        font-size: 14px;
      }

      .author {
        font-size: 14px;
      }

      .userPrice sup {
        vertical-align: baseline;
        font-size: 1em;
      }

      .subtitle {
        font-size: 12px;
        text-transform: capitalize;
        height: 15px;
        line-height: 15px;
      }

      .shop-availability {
        display: none;
      }

      // .f-seeVariantProduct {
      //   display: none;
      // }
    }

    .list& {
      .displayList();

      .info-article {
        padding-right: 1em;
        padding-bottom: 2em;
        position: static;
      }

      // .f-modelAvailable {
      //   font-size: 13px;
      // }

      // .f-seeVariantProduct {
      //   bottom: 5px;
      //   position: absolute;
      //   right: 10px;

      //   .f-seeVariantProduct-button {
      //     display: inline-block;
      //     height: 40px;
      //     line-height: 40px;
      //   }
      // }

      .title {
        font-size: 16px;
        max-height: 42px;
        overflow: hidden;

        a {
          white-space: initial;
          display: block;
          line-height: 20px;
          margin: 0 5px 5px 0;
        }
      }

      .subtitle,
      .format {
        font-size: 13px;
      }

      .HL {
        font-size: 12px;
        left: 0;
        right: auto;
      }
    }

    .actionItem {
      font-size: 12px;

      a {
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.05em;
      }

      .emptyMPLink {
        visibility: hidden;
        font-size: 10px;
      }

      .linkMp {
        color: @color-gray;
      }
    }

    .price-mini-FA {
      text-align: left;
      float: none;
      clear: both;
    }

    .comment {
      font-size: 14px;
    }

    .userOpinion .starsView {
      float: left;
    }

    .userOpinion .count {
      float: left;
      height: 20px;
      padding-left: 5px;
      position: relative;
      top: -2px;
      font-size: 12px;

      .nb {
        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }

      .text {
        display: none;
      }
    }
  }
}

// #region Pricer
.info-article {
  & .userPrice {
    display: inline-block;
    color: @color-red;
    font-weight: bold;

    & sup {
      font-size: 0.6em;
    }
  }

  & .oldPrice {
    display: inline-block;
    font-size: 11px;
    line-height: 11px;
    color: @color-gray-darker;
    text-decoration: line-through;
    position: relative;

    & sup {
      font-size: 1em;
      vertical-align: bottom;
    }

    .discountValue {
      display: none;
    }
  }

  & .HL,
  & .stimuliOPC {
    position: absolute;
    margin: 0 !important;
    padding: 0;
    top: 0;
    left: 0;
  }

  [id^='countdown_'] {
    position: absolute;
    padding: 0;
    bottom: 8px;
    right: 0;
    width: 100%;
  }
}
// #endregion

// ajouts tags mobile smart
#sas-interstitial {
  max-width: 100%;

  img {
    display: block;
    height: auto;
  }
}

// Whaaaaaaaaaaaaaaaaaaaaaaaat ????
.js-accordion {
  overflow: hidden;

  &-title {
    position: relative;

    &::after {
      color: @color-dark-lighter;
      position: absolute;
      font-size: 20px;
      right: 16px;
      top: 8px;
      content: '+';
      font-weight: normal;
    }

    &--more {
      text-align: center;
      border-top: 0;
      height: auto;
      padding: 0.5em 0;
      line-height: 20px;
      font-size: 12px;
      text-decoration: underline;

      &::after {
        content: initial;
      }

      & + .js-accordion-content {
        border-top: 1px solid #efefef;
      }

      &.open::after {
        content: initial !important;
      }
    }

    .openings::after {
      transition: all 0.15s ease-out;
    }

    &.open {
      .openings::after {
        transform: rotate(180deg);
        transition: all 0.15s ease-out;
      }

      &::after {
        content: '−';
      }
    }

    &.close::after {
      content: '+';
    }
  }

  &-content {
    position: relative;
    max-height: 0;
    overflow: hidden;
    border-top: 0px solid transparent;
    transition: all 0.15s ease-out;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    width: 100%;

    &.open {
      width: 100%;
      max-height: 100%;
      border-top: none;
      transition: all 0.15s ease-out;
    }

    .container {
      margin: 0.5em !important;
      padding: 0.5em;
      background: @color-white;
    }
  }
}

.f-gaugeChart {
  margin-top: 10px;
}
