@import (reference) '../../styles/_library-mobile.less';

.f-header {
  height: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 995;
}

.f-header__nav {
  display: flex;
  height: @header-nav-mobile;
  align-items: center;

  // used as background mask
  &::before {
    content: '';
    height: @header-nav-mobile;
    background: @color-white;
    z-index: 2;
    position: absolute;
    width: 100%;
  }

  .searchbar-open & {
    display: none;
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__nav::before {
  background: @color-dark;
}

.f-header__items {
  display: flex;
  height: @header-nav-mobile;
  align-items: center;
  z-index: 2;
  position: relative;
  flex: 1 0 0;
  padding: 0 12px 0 72px;
  opacity: 1;

  // transition: property duration easing delay;
  transition: opacity 0.4s;

  .scrolled-header & {
    opacity: 0;
  }

  .unscrolled-header & {
    transition-delay: 0.2s;
  }
}

.f-header__subnav {
  background: @color-white;
  display: flex;
  align-items: center;
  padding: 2px 24px 8px;
  transition: transform 0.3s ease-in-out;
  font-size: 14px;
  line-height: 16px;
  z-index: 1;
  position: relative;

  .scrolled-subheader & {
    transform: translateY(-82px);
  }

  .scrolled-header &,
  .searchbar-open & {
    display: none;
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__subnav {
  background: @color-dark;
}

.f-header__item {
  @color: @color-dark;

  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 8px;
  background: no-repeat center / auto 24px;

  &--account {
    background-image: colorize(data-uri(icon('person')), @color);
  }

  &--basket {
    background-image: colorize(data-uri(icon('shopping-bag')), @color);
    position: relative;
  }

  &--quotation {
    background-image: colorize(data-uri(icon('document')), @color);
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__item {
  @color: @color-white;

  &--account {
    background-image: colorize(data-uri(icon('person')), @color);
  }

  &--basket {
    background-image: colorize(data-uri(icon('shopping-bag')), @color);
    position: relative;
  }

  &--quotation {
    background-image: colorize(data-uri(icon('document')), @color);
  }
}

.f-header__subitem {
  @color: @color-dark;

  &--help {
    background: colorize(data-uri(icon('help+fill')), @color) no-repeat left center / auto 16px;
    padding-left: 24px;
    white-space: nowrap;
  }

  &--store {
    background: colorize(data-uri(icon('pin+fill')), @color) no-repeat left center / auto 16px;
    padding-left: 24px;
    margin-left: auto;
  }

  &--store2 {
    margin-left: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &::before {
      content: '';
      border-radius: 50%;
      height: 10px;
      margin-right: 8px;
      width: 10px;
      display: inline-block;
      margin-left: 64px;
    }

    &.opened::before {
      background: @color-forestGreen;
    }

    &.closed::before {
      background: @color-red;
    }

    &.drive::before {
      background: @color-warning;
    }
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__subitem {
  @color: @color-white;

  color: @color;

  &--help {
    background: colorize(data-uri(icon('help+fill')), @color) no-repeat left center / auto 16px;
    padding-left: 24px;
  }

  &--store {
    background: colorize(data-uri(icon('pin+fill')), @color) no-repeat left center / auto 16px;
    padding-left: 24px;
    margin-left: auto;
  }
}

.f-header__logo {
  background: colorize(data-uri(icon('icon_i_logo_fnac_02')), #e9aa00) no-repeat 0 49% / 80px auto; // 49% for fix SVG scaling crop issues in some Safari iOS
  flex: 1 0 auto;
  height: 100%;
  overflow: hidden;
  text-indent: -100%;
  white-space: nowrap;
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__logo {
  background-image: colorize(data-uri(icon('icon_i_logo_fnac-pro')), @color-white);
  background-size: 110px;
}

.f-header__basketCount {
  position: absolute;
  background: @color-black;
  color: @color-white;
  font-weight: bold;
  border-radius: 12px;
  left: 22px;
  top: 0;
  font-size: 14px;
  padding: 0 6px;
  line-height: 24px;
  min-width: 24px;
  text-align: center;

  &.isEmpty {
    display: none;
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__basketCount {
  background: @color-white;
  color: @color-black;
}

.f-header__initials {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: @color-white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: @color-gray;
  margin: 8px;

  &--member {
    color: @color-orange;
    background: @color-dark;
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-header__initials--member {
  background: @color-orange;
  color: @color-dark;
}

// Use only on twin sites
.f-header__switch {
  text-transform: uppercase;
  font-weight: bold;
  color: @color-dark;

  margin-right: 32px;
}
