@import './offerList.less';

.f-offerList {
  &__sellerIcon {
    height: 16px;
  }

  &__sellerNameRate {
    position: relative;
    top: -1px;
  }

  &__toolbar {
    display: block;
  }

  &__filters {
    margin-bottom: 10px;
    .f-offerList__filter {
      font-size: 15px;
    }
  }

  &__title {
    padding-bottom: 10px;
  }

  &__item {
    &--info {
      display: inherit;
    }
    &--price {
      margin-bottom: 10px;
    }
    &Price {
      display: inline;
    }
  }

  &__shipping {
    float: none;
  }

  &__stock--available {
    text-align: inherit;
  }

  .f-pagination {
    display: flex;
    justify-content: space-evenly;
  }

  // Forms styles - Desktop to Mobile :
  // See ../../../../../../styles/common/components/forms.less

  input {
    outline: none;
    font-style: normal;
    appearance: none;
  }

  input[type='radio'] {
    display: none;
  }

  input[type='radio'] + label {
    margin-left: 25px;
    position: relative;
    display: inline-block;
    line-height: 18px;
  }

  input[type='radio'] + label::before {
    content: ' ';
    text-align: center;
    display: inline-block;
    margin-left: -25px;
    position: absolute;
    top: 0;
    margin-top: 2px;
    width: 16px;
    height: 16px;
    border: 1px solid @color-gray;
    background: @color-white;

    font-size: 1.8em;
    line-height: 10px;
    border-radius: 50%;

    @media screen and (min-resolution: 0dppx) {
      line-height: 10px;
    }
  }

  input[type='radio'] + label::before {
    font-size: 1.8em;
    line-height: 10px;
    border-radius: 50%;

    @media screen and (min-resolution: 0dppx) {
      line-height: 10px;
    }
  }

  input[type='radio']:checked + label::after {
    content: '';
    background: @color-orange;
    height: 10px;
    width: 10px;
    position: absolute;
    top: 5px;
    left: -22px;
    border-radius: 50%;
  }

  input[type='radio']:not(:checked) + label:hover::after {
    background: @color-silver-darker;
  }

  input[type='radio']:not(:checked) + label:hover::before {
    color: @color-silver-darker;
  }

  input[type='radio']:disabled:not(:checked) + label::before {
    box-shadow: none;
    border-color: #bbbbbb;
    background-color: #dddddd;
  }

  input.hide[type='radio'] + label {
    margin-left: 0;
  }

  input.hide[type='radio'] + label::before {
    display: none;
  }

  //f-select
  .f-select {
    position: relative;
    display: inline-block;
    // margin-bottom: 15px;
    width: 100%;
  }

  .f-select select {
    appearance: none;
    background: transparent;
    cursor: pointer;
    border: 1px solid @color-silver-darker;
    border-radius: 3px;
    color: #7b7b7b;
    display: inline-block;
    padding: 7px 23px 7px 15px;
    outline: 0;
    width: 100%;
  }

  .f-select select::-ms-expand {
    display: none;
  }

  .f-select select:hover,
  .f-select select:focus {
    background: transparent;
    color: @color-black;
  }

  .f-select select:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .f-select--arrow {
    height: 20px;
    position: absolute;
    pointer-events: none;
    right: 5px;
    top: 6px;
    width: 20px;
    @icon-url: icon('chevron-down');
    background: colorize(data-uri(icon('chevron-down')), @color-dark) right center / 0 no-repeat;
    background-size: 20px;
  }

  .f-select select:hover ~ .f-select--arrow,
  .f-select select:focus ~ .f-select--arrow {
    border-top-color: @color-black;
  }

  .f-select select:disabled ~ .f-select--arrow {
    border-top-color: #ccc;
  }
}
