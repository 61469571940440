// --------------------
// Strate REASSURANCE
.f-reassurance {
  display: flex;
  align-items: center;
  background: #232323;
  height: 55px;
  border: 3px solid #fff;
  border-left-width: 6px;
  border-right-width: 6px;

  &__img {
    margin-left: 15px;
    max-height: 45px;
    max-width: 45px;
  }

  &__main {
    color: @color-white;
    font-size: 0;
    margin-left: 10px;
  }

  &__title {
    font-size: 13px;
    line-height: 1.5;
  }

  &__text {
    color: @color-gray;
    font-size: 12px;
    line-height: 1.3;
  }
}
