.FA-experts {
  h2 {
    background: @color-white;
  }
}

/* Communautaire */
.headerCommunautaire(@margin: 0.5em 0) {
  margin: @margin;
  line-height: 1.5em;

  h2 {
    font-size: 0.95em;
    font-weight: bold;
    margin: 0;
    color: @color-dark;
  }

  .visual {
    text-align: center;
    display: inline-block;
  }

  .category {
    .headTitle(@color-skyBlue, @color-white);
  }
}

.infoPost {
  font-size: 0.8em;
  color: @color-gray;

  span {
    color: @color-dark;
  }
}

.infoCom {
  .clearfix();
  margin-bottom: 0.5em;

  .postStars {
    float: left;
  }

  .count {
    display: none;
  }
}

.postViewComs {
  html[lang*='-CH'] & {
    display: none !important;
  }
  float: right;

  &-item {
    color: @color-gray;
    font-size: 0.8em;
    font-weight: bold;

    & + & {
      margin-left: 1em;
    }

    &-icon {
      font-size: 18px;
    }
  }

  .starsView {
    margin: 0 !important;
    width: 100px;

    &::before {
      margin: 0;
      font-size: 21px;
      line-height: 22px;
      height: 24px;
      width: 100px;
    }

    &::after {
      margin: 0;
      font-size: 21px;
      line-height: 22px;
      height: 24px;
      width: 100px;
    }
  }
}

.postButton {
  padding: 1em 0;
}

#spacePostListContainer {
  background: @color-silver-lighter;

  h2 {
    background: transparent;
    border-top: 0;
    font-size: 20px;
    height: auto;
  }

  .postList {
    margin-bottom: 12px;
  }

  .fnacButton,
  .ff-button {
    margin: 1em auto;
    width: 100%;
  }

  .staff-review {
    padding-top: 0.5em;
    background: @color-white;

    &:not(:first-child) {
      margin-top: 0.5em;
    }

    header {
      .headerCommunautaire();
    }

    h2 {
      line-height: 1.2em;
      color: @color-dark;
      margin-bottom: 0.5em;
      border: none;
    }

    a {
      font-weight: bold;
      color: @color-dark;
    }

    .containerArticle {
      padding: 0 1em 1em;

      p {
        font-size: 0.8em;
        color: @color-dark;
        border-bottom: 1px solid @color-silver-darker;
        padding-bottom: 1em;
        margin-bottom: 0.5em;
      }

      .infoCom {
        .clearfix();

        .postStars {
          float: left;
        }

        .postViewComs {
          float: right;

          span {
            color: @color-gray;
            font-size: 0.8em;
            font-weight: bold;
          }

          .icon_i_205_afficher {
            margin-right: 1em;
          }
        }

        .count {
          display: none;
        }
      }
    }
  }
}

.full-post-social {
  background-color: @color-silver-lighter;

  .back {
    background-color: @color-white;
  }

  header {
    .headerCommunautaire(@margin: 0 0.5em);
    background-color: #ffffff;
    padding: 1em 1em 0 1em !important;

    h2 {
      font-size: inherit;
    }
  }

  embed {
    width: 100%;
    height: auto;
  }

  img {
    height: auto !important;
  }

  .containerArticle {
    padding: 0 0.5em 1em 0.5em !important;

    iframe {
      width: 100%;
      height: auto;
      border: none;
    }

    &.motscles {
      padding: 0.5em !important;
    }

    .contentArticle {
      padding: 1em 1em 0.5em 1em;
      background-color: #ffffff;

      .infoExpert {
        margin-top: 1em;
        padding-top: 0.5em;
        border-top: 1px solid @color-silver-darker;

        .visual {
          text-align: center;

          span {
            width: 50px;
            height: 50px;
          }
        }

        .authorName {
          font-size: 0.85em;
        }
      }
    }

    .tag a {
      padding-right: 5px;
      font-size: 12px;
      text-decoration: underline;
    }
  }
}

.full-post-List {
  padding: 0 0.5em;
  background: @color-silver-lighter;
  overflow: hidden;

  .container-fluid {
    padding: 1em;
    margin-top: 0.5em;
    background: #ffffff;

    .ArticlePrice {
      font-size: 13px;
      font-weight: 700;
    }

    &:last-child {
      margin-bottom: 1em;
    }
  }

  h4 {
    font-size: 14px;
    font-weight: 700;
  }

  p {
    font-size: 13px;
  }

  .imgList {
    float: left;
    margin-right: 10px;
  }
}

#spacePostListSorter {
  border-bottom: 1px solid @color-gray;
  margin: 0 0.5em 0.5em 0.5em;
  background-color: @color-silver-lighter;

  div {
    text-align: center;

    &.selected {
      border-bottom: 5px solid @color-dark;
    }
  }

  li {
    text-align: center;
    padding: 0.5em 1em;
  }

  li:first-of-type {
    border-right: 1px solid @color-silver-light;
  }

  span {
    padding: 0 0 0.7em 0;
    display: inline-block;
    font-size: 0.87em;
  }
}

.lst-community {
  padding: 0.5em;
  list-style-type: none;

  li {
    position: relative;

    span {
      position: absolute;
      bottom: 1em;
      left: 1em;
      color: @color-white;
      font-size: 1.3em;
      line-height: 1.2;
      max-width: 50%;
    }
  }
}

.space-post {
  & > #spacePostListContainer {
    padding: 0 0.5em !important;
  }

  & > #spacePostListContainer ~ #spacePostListContainer {
    padding: 0 !important;
  }
}

h1[class^='space-'] {
  position: relative;
  background-position: top right;
  background-size: cover;
  color: @color-white;
  text-transform: uppercase;
  padding: 1em 0.5em;
  font-size: 1em;
}

.COMMUNITY {
  h1[class^='space-'] {
    color: @color-dark !important;
    padding: 0.7em 0.7em 0.5em 0.7em !important;
    font-weight: bold;
    background-color: @color-silver-lighter;
  }

  #content {
    background-color: @color-silver-lighter;

    .back {
      font-size: 14px;
      padding: 0.5em 1em;
      border-bottom: 1px solid #c1c1c1;
      background-color: @color-white;

      &::before {
        content: '';
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
        transform: rotate(45deg);
        display: inline-block;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.f-socialNetworkOwner {
  background-color: #ffffff;
  padding: 0.5em 4%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // &__list {
  //   list-style: none;
  //   padding: 0;
  //   display: flex;
  // }

  // &__li {
  //   margin: 5px;
  // }

  // &__icon {
  //   font-size: 18px;
  // }
}
/* Communautaire */
