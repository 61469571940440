/* HOME PRODUITS */
.home-PRD {
    .breadcrumb {
      background: @color-silver-lighter;
      font-size: 12px;
      color: @color-gray;
      padding: 8px 16px;

      a {
        color: @color-gray;
        display: inline-block;
        white-space: nowrap;

        &:first-child {
          max-width: 30%;
        }
        &:nth-of-type(1n+2){
          &::before {
            content: "-";
            margin-right: 5px;
            display: inline-block;
            vertical-align:top;
          }
        }
      }
      span {
        color: @color-gray;
        display: inline-block;
        font-size: 12px;
        padding: 0 1%;
        text-decoration: underline;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .noeuds {
        .content-shelf {
            &.rayon.open,
            &.state.open {
                position: relative;
                padding-bottom: 41px;
            }
            &.rayon.open {
              max-height: 241px;
            }
            &.state.open {
              max-height: 240px;
            }
        }
    }
}
