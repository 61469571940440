.Home-sponsor {
  background-color: @color-silver-lighter;
}

.FA-sponsor,
.Home-sponsor {
  position: relative;

  .thumbnail-sponsor {
    color: @color-gray-dark;
    font-size: 10px;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}

.Home-sponsor-wrapper {
  background-color: @color-silver-lighter;

  .container {
    background-color: @color-white;
  }

  .thumbnail-sponsor {
    left: 15px;
  }
}
