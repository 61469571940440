@sticky_promo-animation-duration: 0.2s;
.sticky_promo {
  position: fixed;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(100% - 55px));
  transition: transform @sticky_promo-animation-duration ease-in-out;
  z-index: 101;
  background: #d4180e radial-gradient(#ee6698b3 10%, #d4180e 45%);

  &::after {
    content: '';
    background: data-uri('../../../../../Images/sticky-good-deal-bg.svg') no-repeat 88vw -272px;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-position @sticky_promo-animation-duration linear;
  }

  &.visible {
    transform: translateY(0);

    &::after {
      background-position: 80vw -20px;
    }
  }
}

.sticky_promo-header {
  position: relative;
  height: 60px;
  touch-action: none;
  z-index: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: calc(50% - 30px);
    height: 100%;
    z-index: 3;
  }

  &::before {
    left: 0;
    background: linear-gradient(to right, @color-error 10%, rgba(212, 24, 14, 0.2) 60%);
  }

  &::after {
    right: 0;
    background: linear-gradient(to left, @color-error 20%, rgba(212, 24, 14, 0.2) 60%);
  }
}

.sticky_promo-button {
  position: absolute;
  top: -25px;
  left: calc(50% - 30px);
  border: 0;
  outline: 0;
  height: 52px;
  width: 60px;
  border-radius: 50%;
  color: @color-white;
  background: linear-gradient(to bottom, @color-error 26px, transparent 26px);
  z-index: 3;

  &::before {
    content: '';
    .chevron(13px, @color-white, 226deg);
    margin-left: 24px;
    margin-top: -6px;
    border-width: 0 2px 2px 0;
    transition: transform @sticky_promo-animation-duration;

    .sticky_promo.visible & {
      transform: rotate(45deg);
      margin-top: -18px;
    }
  }
}

.sticky_promo-title {
  position: absolute;
  height: 100%;
  width: 100%;
  color: @color-white;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  white-space: nowrap;
}

.sticky_promo-content {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 0 10px 16px;
  transition: @sticky_promo-animation-duration;
  position: relative;
  z-index: 2;
  touch-action: none;
}

.sticky_promo-card {
  padding: 10px;
  width: 46%;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  background: @color-white;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05), 0 5px 8px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  opacity: 0;
  transition: opacity @sticky_promo-animation-duration;

  .sticky_promo.visible & {
    opacity: 1;
  }

  img {
    border-radius: 4px;
  }

  span {
    @lineHeight: 1.2;
    @lines: 2;
    @fontSize: 13px;

    display: block; // fallback
    display: -webkit-box;
    font-size: @fontSize;
    color: @color-dark;
    line-height: @lineHeight;
    max-height: round((@fontSize * @lineHeight) * @lines); // fallback
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: @lines;
    text-align: center;
    margin-top: 8px;
  }
}

.sticky_promo-link {
  width: 96%;
  color: @color-white;
  font-size: 13px;
  border: 1px solid @color-white;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px;
  text-align: center;
}
