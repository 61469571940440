@import '../../../../../styles/_library-mobile.less';

.f-searchbar {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 40px;
  overflow: hidden;
  margin: 2px 16px 8px;
  position: relative;

  .searchbar-open & {
    background: @color-white;
    border-radius: 7px;
    border: 2px solid @color-orange;
    margin: 6px 12px 10px;
    height: 48px;
    overflow: inherit;

    // coz Safari not support radius on outline
    &::before {
      content: '';
      border-radius: 10px;
      position: absolute;
      z-index: -1;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border: 4px solid rgba(@color-orange, 0.2);
    }
  }
}

.f-searchbar__container {
  background: @color-white;
  z-index: 3;
  position: relative;
  display: flow-root;

  // transition: property duration easing delay;
  transition: transform 0.25s @ease-out-quad 0.25s;

  .scrolled-header & {
    transform: translateY(-50px);
  }

  .unscrolled-header & {
    transition-delay: 0s;
  }

  .searchbar-open & {
    transform: none;
    transition: none;
  }
}

// Cannot nested this selectors coz of legacy namespaces
.f-header--pro .f-searchbar__container {
  background: @color-dark;
}

.f-searchbar__input {
  background: @color-silver-lighter;
  border: 0;
  border-radius: 4px;
  color: @color-dark;
  font-size: 16px;
  height: 40px;
  outline: 0;
  padding: 0 84px 0 16px;
  flex: 1 1 100%;
  appearance: none;

  // transition: property duration easing delay;
  transition: transform 0.25s @ease-out-quad 0s;

  .scrolled-header & {
    // transform: translateX(58px);
    // must be hardware accelerated with the translate3d property on iOS to avoid a placeholder display bug on transition
    transform: translate3d(58px, 0, 0);
  }

  .unscrolled-header & {
    transition-delay: 0.25s;
  }

  .searchbar-open & {
    background: @color-white;
    margin: 0 2px 0 0;
    transform: none;
    transition: none;

    &:focus-visible {
      outline: 0 !important;
    }
  }

  &::placeholder {
    color: @color-gray-darker;
    font-style: italic;
  }
}

.f-searchbar__submitBtn {
  background: @color-orange;
  border: 0;
  border-radius: 4px;
  color: @color-dark;
  width: 40px;
  // must be hardware accelerated with the translate3d property on iOS to avoid a blinking display bug on transition
  transform: translate3d(0, 0, 0);

  position: absolute;
  height: 40px;
  right: 0;

  .scrolled-header & {
    // must be hardware accelerated with the translate3d property on iOS to avoid a blinking display bug on transition
    transform: translate3d(0, 0, 0);
  }

  .searchbar-open & {
    right: 2px;
  }
}

.f-searchbar__submitIcon {
  height: 24px;
  width: 24px;
}

.f-searchbar__emptyBtn {
  color: @color-gray;
  right: 42px;
  font-size: 16px;
  line-height: 1;
  width: 40px;
  position: absolute;
  height: 40px;
  // must be hardware accelerated with the translate3d property on iOS to avoid a blinking display bug on transition
  transform: translate3d(0, 0, 0);
}

.f-searchbar__closeBtn {
  display: none;

  .searchbar-open & {
    align-items: center;
    border-radius: 4px;
    color: @color-gray;
    display: flex;
    flex: 0 0 40px;
    font-size: 24px;
    justify-content: center;
    height: 40px;
    margin-left: 8px;
  }
}
