#content {
  min-height: 100vh;
  min-height: 100dvh;
  overflow-x: hidden;
  padding-top: 138px;
  width: 100%;
  display: flex; // allow children to take the full height
  flex-direction: column;

  // MobileApp HACK
  .isMobileApp & {
    margin-top: 0;
    padding-top: 0;
  }
}
