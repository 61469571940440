.stack-cards {
  @image-width: 45%;
  position: relative;
  margin-bottom: 30px;

  &__title {
    .mobile__strata-title();
  }

  &__container {
    position: relative;
    margin: 0 auto;
    max-width: 343px;
    width: 90%;
    height: 176px;
  }

  &__elem {
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    transition: transform 200ms;
    border-radius: 4px;

    &::before {
      content: '';
      left: 0;
      height: 68px;
      bottom: 0;
      position: absolute;
      z-index: -1;
      width: @image-width;
    }

    &--0 {
      background-color: #ce1142;
      &::before {
        background: linear-gradient(270deg, #ce1142 0%, #b10c37 54.41%, #ce1142 100%);
      }
    }

    &--1 {
      background-color: #00857e;
      &::before {
        background: linear-gradient(270deg, #00857e 0%, #006862 53.81%, #00857e 100%);
      }
    }

    &--2 {
      background-color: #232323;
    }

    &--3 {
      background-color: #007bc2;
      &::before {
        background: linear-gradient(270deg, #007bc2 0%, #00639c 54.41%, #007bc2 100%);
      }
    }

    &--4 {
      background-color: #f5b027;
      &::before {
        background: linear-gradient(270deg, #f5b027 0%, #d99a1d 53.81%, #f5b027 100%);
      }

      .stack-cards__content-text .fa-rating__star {
        color: @color-white;
      }
    }

    &--5 {
      background-color: #4f4a48;
      &::before {
        background: linear-gradient(270deg, #4f4a48 0%, #3a3735 53.81%, #4f4a48 100%);
      }
    }
  }

  &__image {
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: center;
    flex-basis: @image-width;
  }

  &__content {
    color: @color-white;
    padding: 20px 16px 25px 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 55%;

    &-subtext {
      font-size: 13px;
      margin-bottom: 5px;
    }

    &-title {
      @lineHeight: 1.2;
      @lines: 2;
      @fontSize: 18px;

      color: @color-white;
      display: block; // fallback
      display: -webkit-box;
      font-size: @fontSize;
      line-height: @lineHeight;
      max-height: round((@fontSize * @lineHeight) * @lines); // fallback
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: @lines;

      &::before {
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &-text {
      font-size: 13px;
      display: flex;
      align-items: flex-end;
      margin-top: 4px;

      .fa-rating__star {
        color: @color-orange;
        height: 16px;
        width: 16px;
        margin-right: 6px;

        &--white {
          color: @color-white;
        }
      }

      .fa-rating__average {
        margin-right: 6px;
      }
    }

    &-price {
      font-size: 21px;
      line-height: 1;
      font-weight: bold;
      margin-top: 22px;

      sup {
        font-size: inherit;
        vertical-align: baseline;
      }
    }
  }

  &__nav {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &__bullet {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    background: @color-silver-lighter;
    margin: 0 3px;
    transition: background 400ms;

    &.active {
      background: @color-orange;
    }
  }

  &-animation-swipe {
    animation-duration: 600ms;
    animation-timing-function: ease-out;

    &-right {
      animation-name: swipe-right;
    }

    &-left {
      animation-name: swipe-left;
    }
  }

  &-animation-tap-to-rotate {
    animation: 2s ease-in-out tap-to-rotate;
  }
}

@keyframes tap-to-rotate {
  50% {
    transform: translate(-10px, -16px) rotate(-6deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
