.f-searchResults {
  &__nb {
    font-size: 13px;
    padding: 16px;
    border-bottom: 1px solid @color-silver-darker;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  // TODO: remove this when refactoring lifestyle
  &--lifestyle {
    .f-searchResults__list {
      border-top: 1px solid #efefef;
    }
  }
}

.Search-ranking_criteria {
  .f-titleFA-link {
    text-decoration: underline;
    font-size: 13px;
    line-height: 17px;
    color: @color-gray-darker;
  }

  .f-icon {
    color: #757575;
    font-size: 16px;
  }
}
