.simpleCarousel {
  article {
    .mini-FA();
    padding: 30px 10px;
    border-bottom-style: none !important;
    border-bottom-width: 0 !important;

    .visuel {
      display: block;
      margin: 0 auto 10px;
      height: 100px;
      max-width: 100%;
      position: relative;

      img:first-child {
        display: block;
        margin: auto;
        max-height: 100%;
        width: auto;

        &.js-LazyImage {
          opacity: 0.25;
          transition: opacity 0.2s ease-in;
        }

        &.js-LazyImage--complete {
          opacity: 1;
        }
      }

      .thumbnail-energyLabel,
      .thumbnail-repairLabel,
      .thumbnail-sustainLabel {
        position: absolute;
        bottom: 0;
        pointer-events: none;
        width: 29px;
      }
      .thumbnail-energyLabel {
        left: 0;
      }
      .thumbnail-repairLabel,
      .thumbnail-sustainLabel {
        right: 0;
      }
    }

    .info-article {
      & > * {
        .text-overflow();
        display: block;
      }
      .price-mini-FA {
        overflow: unset;
      }
      .miniFA__seller {
        white-space: normal;
      }
    }

    .OPC .line .discountValue {
      display: inline-block;
    }

    // .f-modelAvailable {
    //   font-size: 13px;
    // }

    // .f-seeVariantProduct {
    //   display: none !important;
    // }
  }

  &__sponsored {
    color: @color-gray-dark;
    display: block;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    right: 10px;
    z-index: 1;
  }

  .viewAll {
    display: inline-block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    line-height: 1em;
    color: @color-orange;
    margin-top: 6em;
  }
}

// Simple carousel without JS
.simpleCarousel {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  padding-bottom: 5px;
  position: relative;
  width: 100%;
  scroll-snap-type: x mandatory;

  .simpleCarousel-element {
    background: @color-white;
    border: 1px solid @color-silver-light !important;
    padding: 30px 10px;
    position: relative;
    min-height: 300px;
    vertical-align: top;
    flex: 1 0 0; // allow to grow (>2: min-width, 2: 50% each, 1: 100%)
    min-width: 47%; // show at least 2 + a part of an other
    text-align: left;

    &--noSeparation {
      text-align: center;
      border-width: 1px 0 !important;
      min-width: 40%;
    }

    &--bigger {
      min-width: 80% !important;
    }

    &--ob {
      width: 180px;
      min-width: 0;
      padding: 0;
      vertical-align: top;
    }

    & .OPC .line {
      width: 100%;
    }

    & .OPC .label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 100%;
      font-size: 9.5px !important;
    }

    & .OPC .discountValue {
      overflow: auto;
    }
  }

  .simpleCarousel-element-img {
    height: 100px;
    width: auto;
  }

  .thumbnail-moreOffers {
    width: calc(100% - 50px);
  }

  .FlashSaleCountDown {
    display: block;
    .f-countdown();
  }
}

// disable carousel-arrow on mobile.
.Carousel-arrow {
  display: none;
}

// execption carousel marque
.brand-list .simpleCarousel .simpleCarousel-element {
  min-height: 100px;
}
