// HOW TO USE IT
// markup HTML
// ex :
//
//    <select class="ff-select">
//       <option>select option 1</option>
//       <option>example : option 2</option>
//    </select>
.ff-select {
  padding: 10px 50px 0 0;
  font-size: 15px;
  line-height: 21px;
  color: @color-dark;
  background-color: @color-white;
  appearance: none;
  border: none;
  border-bottom: 1px solid @color-gray-darker;
  background-image: colorize(data-uri(icon('chevron-down')), @color-dark);
  background-repeat: no-repeat;
  background-position: right 10px top 15px, 0 0;
  background-size: 12px auto, 100%;

  &__option {
    font-size: 13px;
    line-height: 16px;
    padding: 5px;
    color: @color-dark;
  }

  &::-ms-expand {
    display: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &--full {
    width: 100%;
  }

  &--border {
    padding: 10px 40px 10px 15px;
    border: 1px solid @color-silver-darker;
    background-position: right 15px top 50%, 0 0;
    color: @color-gray;

    &:focus {
      border-color: @color-silver-darker;
    }
  }
}
