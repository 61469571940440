#productList {
  header {
    //.mobile__strata-header();

    a {
      //.mobile__strata-link();

      .icon {
        margin-left: 5px;

        &::before {
          font-size: 12px;
        }
      }
    }
  }

  .simpleCarousel-element {
    max-height: 270px;
    max-width: 50%; // allow to grow but only 50%, useful for <= 2
  }

  article {
    border-top: 1px solid @color-silver-darker;
    border-bottom: 0;
  }
}
