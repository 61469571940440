// .buttons {
//   margin: 0.5em 0;
// }

/* Nouvel FA */

// .f-faFnacPlus {
//   font-size: 1.3rem;
//   background: @color-white;

//   &__logo {
//     height: 13px;
//     width: 42px;
//   }

//   .ProductBuyBox-expressPlusLabel {
//     font-size: 1.3rem;
//   }

//         &::before {
//           background: @color-white;
//           border: 1px solid rgba(@color-black, 0.5);
//           border-width: 1px 0 0 1px;
//           content: '';
//           height: 12px;
//           left: -13px;
//           position: absolute;
//           top: 50%;
//           transform: translate(50%, -50%) rotate(-45deg);
//           width: 12px;
//           right: 0;
//         }
//       }

//       &:focus &__tooltip {
//         opacity: 1;
//         visibility: visible;
//       }
//     }

//     & &__deliveryDate {
//       display: block;
//       color: @color-lime-light;
//       line-height: 15px;
//     }
//   }

//   .fnacButton {
//     width: calc(100% - 2em);

//     &--fixed {
//       width: 100%;
//     }
//   }

//   .bientot {
//     color: @color-orange;
//     font-size: 14px;
//     font-weight: bold;
//     margin: 16px 0 6% 5%;

//     .update {
//       display: inline;
//       margin-right: 1px;

//       & + .titleAvailability {
//         color: @color-orange;
//       }
//     }
//   }

//   .shipping-info {
//     margin: 0.5em 0 0;
//     padding: 0 1em 0 5%;
//     position: relative;

//     .freeDelivery {
//       color: @color-red;
//     }

//     .viewDetails {
//       color: @color-gray;
//       margin: 0 1em;
//       text-align: right;
//     }
//   }

//   .f-free-shipping-important {
//     color: @color-red2;
//   }

//   .f-buyBox-deliveryPrice,
//   .f-buyBox-deliveryDate {
//     color: @color-red;
//     font-weight: bold;

//     sup {
//       font-size: 1em;
//       top: 0;
//     }
//   }
// }

.f-buyBox-shipping {
  width: 100%;
}

.bottomSticky {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-basis: 0;
  min-height: 62px;
  padding: 8px 10px 8px 16px;
  background-color: @color-white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  border-radius: 0;
  z-index: 1003; // hight value needed because of compare banner
  transition: 0.25s;
  transform: translateZ(0);
  width: 100%;
  box-shadow: 0 0 10px 0 rgba(@color-black, 0.13);

  // &:not(&--visible) {
  //   transform: translateY(-100%);
  //   bottom: -10px;
  // }

  &__price {
    flex: 1;

    &Main {
      //Responsive Typo
      @min-width: 32rem;
      @max-width: 42.5rem;
      @min-font: 1.2rem;
      @max-font: 2.7rem;
      @ideal-value: calc(@min-font + (@max-font - @min-font) * ((200vw - @min-width) / @max-width));

      display: block;
      font-size: @max-font;
      font-size: clamp(@min-font, @ideal-value, @max-font);
      line-height: 30px;
      font-weight: bold;
    }

    &Secondary {
      display: block;
      font-size: 15px;
      line-height: 15px;
      text-decoration: line-through;
      font-weight: bold;
    }
  }

  .ff-button {
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;

    // override ff-button--block
    display: flex;
    width: auto;
  }

  .ff-button-label {
    margin-left: 5px;
    max-width: inherit;
  }
}
