.f-basketModal {
  &__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    padding: 25px;

    .icon {
      color: @color-lime;
    }
  }

  &__action {
    text-align: center;
    font-size: 13px;
    padding: 0 25px 25px 25px;
  }

  &__addToBasketBtn {
    margin: 0 0 15px;
    min-height: 48px;
  }

  &__continueBtn {
    width: 100%;
    margin: 0;
    min-height: 48px;
  }

  &__youMayAlsoLike {
    background-color: @color-silver-lighter;
    padding: 15px;

    @media (orientation: landscape) and (max-width: 600px) {
      display: none;
    }
  }

  .simpleCarousel {
    @media (orientation: landscape) and (max-width: 600px) {
      display: none;
    }
  }
}

.f-basketPopin-WarrantyBlock {
  padding: 0 25px 15px;

  .f-basketPopin-subtitle {
    font-size: 14px;
    margin-left: 5px;
    color: @color-orange;
    padding-top: 15px;
    border-top: 1px dashed @color-silver-darker;
    font-weight: bold;
  }

  .f-basketPopin-Warranty {
    border: 1px solid @color-silver-darker;
    border-left: 0;
    box-shadow: inset 10px 0 0 @color-green-lighter;
    padding: 0px 15px 0 20px;
    position: relative;
    margin-top: 10px;

    .f-basketPopin-WarrantyItem {
      font-size: 14px;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .f-basketPopin-warrantyContainer {
        width: 70%;

        .f-basketPopin-WarrantySubTitle {
          font-weight: 700;
          width: 95%;
        }

        .f-basketPopin-WarrantyMonthly {
          font-weight: 700;
          color: @color-green-lighter;
          width: 95%;
          display: block;
        }

        .f-basketPopin-WarrantyPrice {
          color: @color-dark;
          font-weight: 400;
        }
      }

      .f-basketPopin-basketAdd {
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 30%;

        // &:hover,
        // &:active {
        //   background: inherit;
        //   color: inherit;
        // }

        &--added {
          background: @color-forestGreen;
          color: @color-white;
          border: none;

          &:hover {
            background: @color-forestGreen;
            color: @color-white;
            cursor: default;
          }

          & .ff-button-label {
            display: none;
          }

          .ff-button-icon {
            margin: auto;
          }
        }
      }
    }

    .f-basketPopin-WarrantyItem:not(:first-child) {
      border-top: 1px dashed @color-silver-darker;
    }
  }
}

.BasketPopin {
  &-progress-container #darcosInfoTooltip {
    display: none;
  }
  &-progress-text {
    font-size: 13px;
    text-align: center;

    &--completed::before {
      background-image: colorize(data-uri(icon('check-circle')), @color-forestGreen);
      content: '';
      width: 17px;
      height: 17px;
      display: inline-block;
      vertical-align: top;
      margin-right: 5px;
    }
  }

  &-progress-tooltip-icon {
    background-image: colorize(data-uri(icon('help')), @color-black);
    width: 13px;
    display: inline-block;
    height: 13px;
    transform: translateY(2px);
  }

  &-progress-container .f-progressBar {
    margin: 12px 0;
  }
}

.drop-element.LayerBasketDarcosTooltip {
  z-index: 99999;
}

.LayerBasketDarcosTooltip.drop-theme-arrows .drop-content {
  max-width: 281px;
  font-size: 15px;
  background: @color-dark;
  color: @color-white;
  border-color: @color-dark;
  &::before {
    top: 100%;
    border-bottom-color: @color-dark;
    right: 50%;
    border-top: 0 transparent;
    transform: rotate(180deg);
  }
}

.BasketPopin-tooltip {
  display: inline;
  position: relative;

  &:focus &-content {
    display: block;
  }

  &-content {
    display: none;
    position: absolute;
    width: 250px;
    background: #232323;
    color: #fff;
    top: -76px;
    left: -213px;
    margin-bottom: 16px;
    padding: 1em;
    border: 1px solid #232323;
    border-radius: 5px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-width: 16px;
      border-style: solid;
      top: 100%;
      left: 82%;
      border-top-color: #232323;
    }
  }
}
